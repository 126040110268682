import React from "react"
import ReactMarkdown from "react-markdown"
import Iframe from 'react-iframe'
import styles from "./adresseBloc.module.css"

export default (props) => (
  <div 
    className={styles.adresseBlocContain}
  >
    <div className={styles.left}>
      <div>
        <ReactMarkdown source={props.adressText} />
      </div>
      <div className={styles.imgContain}>
        {props.img && <img 
          src={props.img.fluid.src}
          alt="place"
          width={props.img.width}
          height={props.img.height}
        />}
      </div>
    </div>
    <div className={styles.right}>
      <Iframe url={props.map}
        width="600px"
        height="450px"
        id="myId"
        frameborder="0"
        className="myClassname"
        display="initial"
        position="relative"
        allowFullScreen/>
    </div>
  </div>
);