import React from "react"
import Situation from "templates/situation"
import { graphql } from 'gatsby'
import { getFieldLang } from "utils"

export default (props) => {
  const {
    data: {
      allDatoCmsSituation
    },
    pageContext: {
      lang,
      slugs
    }
  } = props;

  const data = allDatoCmsSituation && allDatoCmsSituation.edges[0].node;

  return (
    <div>
      {data && 
        <Situation
          data={{ 
            ...data,
            texteSituationArticle: data[`texteSituationArticle${lang}`],
            texteAdresseSituation: data[`texteAdresseSituation${lang}`],
          }}
          location={{ pathname: '/' }}
          currentPage={ props.location.pathname }
          lang={lang}
          slugs={slugs}
        />
      }
    </div>
  );
};

export const query = graphql`
  query SituationQuery($website: String!) {
    allDatoCmsSituation(filter: {website: {eq: $website}}) {
      edges {
        node {
          illustrationsPageSituation {
            id
            fluid(maxWidth: 1920, imgixParams: {fm: "jpg", auto: "compress"}) {
              ...GatsbyDatoCmsFluid
            }
          }
          illustrationsSituationArticle {
            id
            fluid(imgixParams: {fm: "jpg", auto: "compress"}) {
              ...GatsbyDatoCmsFluid
            }
          }
          texteSituationArticleFr
          texteSituationArticleEn
          texteSituationArticleIt
          texteSituationArticleDe
          texteAdresseSituationFr
          texteAdresseSituationEn
          texteAdresseSituationIt
          texteAdresseSituationDe
          illustrationAdresseSituation {
            id
            width
            height
            fluid(imgixParams: {fm: "jpg", auto: "compress"}) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  }
`