import React from "react";
import Layout from "components/layout";
import AdresseBloc from "components/AdresseBloc/";
import Article from "components/Article/";
import ImageContain from "components/ImageContain/";
import ImageParallax from "components/ImageParallax/";
import { ParallaxProvider } from "react-scroll-parallax";
import { enumTheme } from "config";
import { getCurrentLang } from "utils";
import { getPageTitle } from "../slugs";

export default props => {
  const {
    data: {
      illustrationsPageSituation,
      illustrationsSituationArticle,
      texteSituationArticle,
      texteAdresseSituation,
      illustrationAdresseSituation
    },
    slugs,
    lang
  } = props;

  return (
    <ParallaxProvider>
      <Layout
        slugs={slugs}
        lang={lang}
        pageTitle={getPageTitle("situation", lang)}
        headerScroll={0}
        footerTheme={enumTheme.WHITE}
        socialMediaIsDark={true}
        socialMediaScroll={0}
        socialMediaOffset={0}
        baseUrl={getCurrentLang(props.location.pathname)}
        currentPage={props.currentPage}
      >
        <Article
          theme={enumTheme.WHITE}
          imgFull={illustrationsSituationArticle[0]}
          img1={illustrationsSituationArticle[1]}
          img2={illustrationsSituationArticle[2]}
          img3={illustrationsSituationArticle[3]}
          text={texteSituationArticle}
          paddingBottom={0}
          baseUrl={getCurrentLang(props.location.pathname)}
        />
        <ImageContain
          theme={enumTheme.WHITE}
          img={illustrationsPageSituation[0]}
          margin={"37px 100px 17px 100px"}
          height={1}
        />
        <ImageContain
          theme={enumTheme.WHITE}
          img={illustrationsPageSituation[1]}
          margin={"17px 100px 37px 100px"}
          height={1}
        />
        <ImageParallax img={illustrationsPageSituation[2]} />
         <AdresseBloc
            adressText={texteAdresseSituation}
            img={illustrationAdresseSituation}
            map={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2985.8833870774497!2d9.303475415430837!3d41.55012207924954!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x67c7fbf72ff9b027!2sVillas+Rossa+-+Palombaggia!5e0!3m2!1sfr!2sfr!4v1548940626576"}
          />
      </Layout>
    </ParallaxProvider>
  );
};
